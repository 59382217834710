
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as testWAmRdgC_JsMpRTj2Lw1hcgaT_45NU9xgELwBJuztcYPh8Meta } from "/app/pages/test.vue?macro=true";
import { default as kontaktZnpy40LDWxDvLCIaJpWZIY_45EOYZubgQiFqL2fBVRV4kMeta } from "/app/pages/kontakt.vue?macro=true";
import { default as _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexXDrvC4X9cQh98rUTaKV7n9SAzKfJcTLnbJGv9g_YdesMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91tag_93P85g9ZajFHPtm_45V89K1P6HzPWlsBhhphoOSSf2OEd3QMeta } from "/app/pages/tags/[tag].vue?macro=true";
import { default as datenschutzVT8DMAvp2zZGuffv9SvwwqtsvPkjTEedGWvJnTmYVY4Meta } from "/app/pages/datenschutz.vue?macro=true";
import { default as indexwSXudBzCI6hxsl49xqf7VXZq_7itvx_45Ae_45Ebad_45BrDQMeta } from "/app/pages/careers/index.vue?macro=true";
import { default as kontakt_dankeSExWVufR_gMOPnJ8D63JWgwQWsShhGNtCWYZHK7odX0Meta } from "/app/pages/kontakt_danke.vue?macro=true";
import { default as _91_46_46_46slug_93DyJA96rzgxfedZ4HEhg7HDMlrN2w0ydQ_45E5L6m8JyCcMeta } from "/app/pages/blog/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93oCRB9isLlIysVmqVR52j8imUhYMlnZd3drXJpOYsYowMeta } from "/app/pages/careers/[...slug].vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "test___de",
    path: "/de/test",
    component: () => import("/app/pages/test.vue")
  },
  {
    name: "test___en",
    path: "/en/test",
    component: () => import("/app/pages/test.vue")
  },
  {
    name: "test___es",
    path: "/es/test",
    component: () => import("/app/pages/test.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "kontakt___de",
    path: "/de/kontakt",
    component: () => import("/app/pages/kontakt.vue")
  },
  {
    name: "kontakt___en",
    path: "/en/kontakt",
    component: () => import("/app/pages/kontakt.vue")
  },
  {
    name: "kontakt___es",
    path: "/es/kontakt",
    component: () => import("/app/pages/kontakt.vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "tags-tag___de",
    path: "/de/tags/:tag()",
    component: () => import("/app/pages/tags/[tag].vue")
  },
  {
    name: "tags-tag___en",
    path: "/en/tags/:tag()",
    component: () => import("/app/pages/tags/[tag].vue")
  },
  {
    name: "tags-tag___es",
    path: "/es/tags/:tag()",
    component: () => import("/app/pages/tags/[tag].vue")
  },
  {
    name: "datenschutz___de",
    path: "/de/datenschutz",
    component: () => import("/app/pages/datenschutz.vue")
  },
  {
    name: "datenschutz___en",
    path: "/en/datenschutz",
    component: () => import("/app/pages/datenschutz.vue")
  },
  {
    name: "datenschutz___es",
    path: "/es/datenschutz",
    component: () => import("/app/pages/datenschutz.vue")
  },
  {
    name: "careers___de",
    path: "/de/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___en",
    path: "/en/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___es",
    path: "/es/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "kontakt_danke___de",
    path: "/de/kontakt_danke",
    component: () => import("/app/pages/kontakt_danke.vue")
  },
  {
    name: "kontakt_danke___en",
    path: "/en/kontakt_danke",
    component: () => import("/app/pages/kontakt_danke.vue")
  },
  {
    name: "kontakt_danke___es",
    path: "/es/kontakt_danke",
    component: () => import("/app/pages/kontakt_danke.vue")
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug(.*)*",
    component: () => import("/app/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug(.*)*",
    component: () => import("/app/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug(.*)*",
    component: () => import("/app/pages/blog/[...slug].vue")
  },
  {
    name: "careers-slug___de",
    path: "/de/careers/:slug(.*)*",
    component: () => import("/app/pages/careers/[...slug].vue")
  },
  {
    name: "careers-slug___en",
    path: "/en/careers/:slug(.*)*",
    component: () => import("/app/pages/careers/[...slug].vue")
  },
  {
    name: "careers-slug___es",
    path: "/es/careers/:slug(.*)*",
    component: () => import("/app/pages/careers/[...slug].vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/de/developer/crm",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/de/dev",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/de/developer/crm/*",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/de/affiliate/info",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/de/imprint",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/en/imprint",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/de/contact",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/en/contact",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/jobs",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/karriere",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]