<script lang="ts" setup>
const { locale, t } = useI18n();

useHead({
  htmlAttrs: { lang: locale },
  title: t('meta.title'),
  meta: [
    { name: 'description', content: t('meta.content') },
    { name: 'keywords', content: t('meta.keywords') },
    { property: 'og:image', content: t('meta.image-url') },
  ],
});
</script>

<template>
  <NuxtPage />
</template>

<style lang="scss">
@import '~~/stylesheets/default';
</style>
